<template>
  <div>
    <validation-observer
      ref="inlineEditingGoals"
      v-slot="{ invalid }"
    >
      <div class="level">
        <div class="level-left" />

        <div class="level-right">
          <div class="level-item">
            <b-button
              v-if="isEditing"
              ref="saveEntryButton"
              :disabled="invalid"
              size="is-small"
              icon-left="save"
              type="is-success"
              @click="updateLinkedId"
            >
              save
            </b-button>
          </div>
          <div class="level-item">
            <b-button
              v-if="isEditing"
              ref="cancelEntryButton"
              size="is-small"
              class="mr-4"
              icon-left="close"
              type="is-success"
              @click="cancelUpdate"
            >
              cancel
            </b-button>
          </div>
        </div>
      </div>

      <b-table
        ref="linkedIdsTable"
        v-click-outside-dg="onClickOutsideTable"
        :mobile-cards="false"
        :loading="loading"
        :data="linkedIds.content"
        :checkable="customizations.isCheckable"
        :checked-rows.sync="checkedRows"
        paginated
        backend-pagination
        :total="total"
        :per-page="perPage"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :narrowed="customizations.isNarrowed"
        :bordered="customizations.isBordered"
        :striped="customizations.isStriped"
        :sticky-header="customizations.isStickyHeaders"
        :scrollable="customizations.isScrollable"
        backend-filtering
        :debounce-search="800"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        @sort="onSort"
        @filters-change="onFilter"
        @page-change="onPageChange"
        @update:selected="onClickOutsideEditing"
      >
        <b-table-column
          v-for="(column, index) in columns"
          :key="index"
          :label="column.label"
          :field="column.field"
          :searchable="column.searchable"
          :sortable="column.sortable"
          :visible="column.visible"
        >
          <template
            slot="searchable"
            slot-scope="props"
          >
            <template>
              <b-input
                v-show="
                  column.searchField === 'salesID' ||
                    column.searchField === 'description' ||
                    column.searchField === 'salesperson'
                "
                v-model="props.filters[`${column.searchField}`]"
                placeholder="Search..."
                icon="search"
                size="is-small"
              />
            </template>
          </template>

          <template v-slot="props">
            <template v-if="column.field === 'actions'">
              <div class="buttons">
                <b-tooltip
                  type="is-info"
                  label="Edit"
                  position="is-bottom"
                >
                  <b-button
                    type="is-text"
                    class="has-text-grey"
                    icon-right="edit"
                    @click="onEdit(props.row)"
                  />
                </b-tooltip>

                <b-tooltip
                  type="is-info"
                  label="Delete"
                  position="is-bottom"
                >
                  <b-button
                    type="is-text"
                    class="has-text-grey"
                    icon-right="trash"
                    @click="deleteLinkedId(props.row)"
                  />
                </b-tooltip>
              </div>
            </template>
            <template v-else-if="column.field === 'salesID'">
              <template v-if="isEditing && props.row.id === selectedLinkedId">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="sales id"
                >
                  <b-field
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                  >
                    <b-input
                      v-model="salesID"
                      size="small"
                    />
                  </b-field>
                </validation-provider>
              </template>
              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row)"
                >
                  {{ props.row[column.field] }}
                </span>
              </template>
            </template>
            <template v-else-if="column.field === 'salesperson'">
              <template v-if="props.row['user'] !== null">
                {{ props.row['user'].fullName }}
              </template>
              <template v-else />
            </template>
            <template v-else>
              {{ props.row[column.field] }}
            </template>
          </template>
        </b-table-column>

        <template slot="footer">
          <table-footer
            :first-item="firstItem"
            :page="page"
            :per-page="perPage"
            :total="total"
          >
            <template #page-dropdown>
              <b-dropdown
                v-model="perPage"
                aria-role="list"
                position="is-top-left"
              >
                <b-button
                  slot="trigger"
                  type="is-info"
                  size="is-small"
                  icon-right="chevron-down"
                >
                  {{ perPage }}
                </b-button>

                <template v-for="(pageCount, index) in pages">
                  <b-dropdown-item
                    :key="index"
                    :value="pageCount"
                  >
                    {{ pageCount }}
                  </b-dropdown-item>
                  <hr
                    :key="pageCount"
                    class="dropdown-divider"
                  >
                </template>
              </b-dropdown>
            </template>
          </table-footer>
        </template>

        <template slot="empty">
          <empty-table table-name="linked-ids" />
        </template>
      </b-table>

      <b-modal
        ref="inlineEditingSavePrompt"
        v-model="isSavePromptActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="inline editing save modal"
        aria-modal
      >
        <template #default="props">
          <inline-editing-save-prompt
            @save="updateLinkedId"
            @close="props.close"
          />
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { TableFooter, EmptyTable, InlineEditingSavePrompt } from '@/components/Shared';
import SearchMixin from '@/mixins/SearchMixin';

export default {
  name: 'LinkedIdsTable',

  components: {
    TableFooter,
    EmptyTable,
    InlineEditingSavePrompt,
    ValidationObserver,
    ValidationProvider,
  },

  filters: {
    /**
     * Truncate text that is more than 100 characters in length
     *
     * @param {String} text text to truncate
     * @returns truncated text
     */
    truncateText(text) {
      const charactersLength = 100;
      const textEnding = '...';
      if (text.length <= charactersLength) {
        return text;
      }
      return text.slice(0, charactersLength - textEnding.length) + textEnding;
    },
  },

  mixins: [SearchMixin],

  data: () => ({
    checkedRows: [],

    total: 0,
    page: 1,
    perPage: 25,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    sortField: 'updatedAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    isEditing: false,
    selectedLinkedId: '',

    isSavePromptActive: false,

    savePromptOpen: false,

    filters: {},

    exportFields: {},

    searchClause: '',

    columns: [
      {
        id: '1',
        label: 'Linked ID',
        field: 'salesID',
        sortable: true,
        searchable: true,
        searchField: 'salesID',
        exportField: 'salesID',
        sticky: false,
        visible: true,
      },
      {
        id: '2',
        label: 'Description',
        field: 'description',
        sortable: true,
        searchable: true,
        searchField: 'description',
        exportField: 'description',
        sticky: false,
        visible: true,
      },
      {
        id: '3',
        label: 'Salesperson',
        field: 'salesperson',
        sortable: true,
        searchable: true,
        searchField: 'salesperson',
        exportField: 'salesperson',
        sticky: false,
        visible: true,
      },
      {
        id: '4',
        label: 'Actions',
        field: 'actions',
        sortable: true,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: true,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },

  }),

  computed: {
    ...mapGetters({
      grid: 'Grids/getLinkedIdsGrid',
      loading: 'DataGrids/getLoading',
      linkedIds: 'DataGrids/getLinkedIds',
    }),

    salespersonData: {
      get() {
        return this.$store.state.Settings.linkedIds.linkedId.user.fullName;
      },
    },

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

    linkedId: {
      get() {
        return this.$store.state.Settings.linkedIds.linkedId;
      },
    },

    salesID: {
      get() {
        return this.$store.state.Settings.linkedIds.linkedId.salesID;
      },

      set(value) {
        return this.$store.commit('Settings/SET_LINKED_ID_SALES_ID', value);
      },
    },

  },

  watch: {
    perPage: {
      handler() {
        this.fetchLinkedIds();
      },
      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchLinkedIdsGrid', {
        gridName: 'linked-ids',
        organizationId,
      });
      if (response) {
        const isConfigurationValid = this.configurationValidator();
        if (isConfigurationValid) {
          this.setGrid();
        }
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'linked-ids',
          customizations: this.customizations,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    this.filters = { ...this.$route.query };
    this.fetchLinkedIds();
  },

  destroyed() {
    if (localStorage.getItem('access_token')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'linked-ids',
        customizations: this.customizations,
      });
    }
    this.$store.commit('Settings/SET_LINKED_IDS', []);
  },

  methods: {
    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    configurationValidator() {
      const defaultConfiguration = this.columns.map((el) => el.label)
        .sort();
      const userConfiguration = this.grid.columns.map((el) => el.label)
        .sort();
      const result = defaultConfiguration.every(
        (configuration, index) => configuration === userConfiguration[index],
      );
      return result;
    },

    onClickOutsideEditing(row) {
      if (row.id && this.isEditing) {
        if (row.id !== this.selectedLinkedId) {
          this.savePromptOpen = true;
          this.isSavePromptActive = true;
        }
      }
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.savePromptOpen = true;
        this.isSavePromptActive = true;
      }
    },

    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex((el) => el.label === a.label)
          - this.grid.columns.findIndex((el) => el.label === b.label),
      );
      const newColumns = this.columns.map((el, index) => ({
        ...el,
        visible: this.grid.columns[index].visible,
      }));
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
    },

    /**
     * onEdit
     *
     * @param {object} goalItem
     */
    onEdit(row) {
      this.$store.dispatch('Settings/fetchLinkedId', row.id);
      this.selectedLinkedId = row.id;
      this.$emit('is-editing', true);
      this.isEditing = true;
    },

    /**
     * cancelUpdate
     *
     */
    async cancelUpdate() {
      try {
        await this.fetchLinkedIds();
        this.isEditing = false;
        this.$emit('is-editing', false);
        this.selectedLinkedId = null;
        this.$store.commit('Settings/CLEAR_LINKED_ID');
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * fetchLinkedIds
     *
     */
    async fetchLinkedIds() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters)
          .forEach((key) => filters[key] === '' && delete filters[key]);
      }

      try {
        await this.$store.dispatch('DataGrids/fetchLinkedIds', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
        });
        this.total = this.linkedIds.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    async updateLinkedId() {
      const payload = { ...this.linkedId };
      await this.$store.dispatch('Settings/updateLinkedId', {
        id: payload.id,
        data: {
          salesID: payload.salesID,
        },
      });
      await this.fetchLinkedIds();
    },

    async deleteLinkedId(payload) {
      await this.$buefy.dialog.confirm({
        message: 'Are you sure?',
        closeOnConfirm: true,
        type: 'is-info',
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Settings/deleteLinkedId', payload.id);
            await this.fetchLinkedIds();
          } catch (error) {
            console.error(error);
          }
        },
      });
    },

    /**
     * onPageChange
     *
     * handle page change events
     * @param {Number} page
     */
    onPageChange(page) {
      this.page = page;
      this.from += this.perPage;
      this.fetchLinkedIds();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchLinkedIds();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = { ...this.filters, ...filters };
      this.fetchLinkedIds();
    },
  },
};
</script>

<style lang="css" scoped>
</style>
