<template>
  <div>
    <div class="level">
      <div class="level-left" />

      <div class="level-right">
        <div class="level-item">
          <b-button
            v-if="isEditing"
            ref="saveEntryButton"
            size="is-small"
            class="mr-4"
            icon-left="save"
            type="is-success"
            @click="updateCustomField"
          >
            save
          </b-button>

          <b-button
            v-if="isEditing"
            ref="cancelEntryButton"
            size="is-small"
            class="mr-4"
            icon-left="close"
            type="is-success"
            @click="cancelUpdate"
          >
            cancel
          </b-button>

          <b-button
            v-if="Object.keys(filters).length > 0"
            size="is-small"
            icon="power-off"
            @click="resetFilters"
          >
            Reset Filters
          </b-button>
        </div>
      </div>
    </div>

    <!-- v-click-outside-dg="onClickOutsideTable" -->
    <b-table
      ref="customFieldsTable"
      :mobile-cards="false"
      :loading="loading"
      :data="customFieldsData.content"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
      @update:selected="onClickOutsideEditing"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <template>
            <b-select
              v-if="column.searchField === 'type'"
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Select filter"
              size="is-small"
            >
              <option
                v-for="option in fieldTypes"
                :key="option.id"
                :value="option.name"
              >
                {{ option.name }}
              </option>
            </b-select>

            <b-select
              v-if="column.searchField === 'objectName'"
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Select filter"
              size="is-small"
            >
              <option
                v-for="option in objectIds"
                :key="option.id"
                :value="option.code"
              >
                {{ option.name }}
              </option>
            </b-select>

            <b-input
              v-if="
                column.searchField === 'label' ||
                  column.searchField === 'lookupName'
              "
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
            />

            <b-switch
              v-if="column.searchField === 'isRequired'"
              v-model="props.filters[`${column.searchField}`]"
            />
          </template>
        </template>

        <template v-slot="props">
          <template v-if="column.field === 'actions'">
            <div class="buttons">
              <b-tooltip
                type="is-info"
                label="Edit"
                position="is-right"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="edit"
                  @click="onEdit(props.row)"
                />
              </b-tooltip>

              <b-tooltip
                type="is-primary"
                label="Delete"
                position="is-bottom"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="trash"
                  @click="deleteCustomField(props.row)"
                />
              </b-tooltip>
            </div>
          </template>

          <b-tooltip
            v-else
            label="Double-click to edit"
            class="is-info"
          >
            <template
              v-if="column.field === 'lookupName'"
            >
              <!-- <template
              v-if="isEditing && props.row.id === selectedCustomFieldId
                && (props.row.type === 'DROP_DOWN' || fieldType === 'DROP_DOWN')"
            >
              <b-select
                v-model="lookupName"
                placeholder="Select lookups"
                size="is-small"
                expanded
              >
                <option
                  v-for="(option, optionIndex) in lookupCodes"
                  :key="optionIndex"
                  :value="option"
                >
                  {{ option }}
                </option>
              </b-select>
            </template> -->
              <template>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row)"
                >
                  {{ props.row[column.field] }}
                </span>
              </template>
            </template>

            <template
              v-else-if="column.field === 'label'"
            >
              <template v-if="isEditing && props.row.id === selectedCustomFieldId">
                <b-input
                  v-model="label"
                  size="is-small"
                />
              </template>
              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row)"
                >
                  {{ props.row[column.field] }}
                </span>
              </template>
            </template>

            <template
              v-else-if="column.field === 'objectName'"
            >
              <template>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row)"
                >
                  {{ props.row[column.field] }}
                </span>
              </template>
            </template>

            <template
              v-else-if="column.field === 'isRequired'"
            >
              <template v-if="isEditing && props.row.id === selectedCustomFieldId">
                <b-switch
                  v-model="isRequired"
                />
              </template>
              <template v-else>
                <span
                  class="is-pointer"
                  @dblclick="onEdit(props.row)"
                >
                  {{ props.row[column.field] ? 'Yes' : 'No' }}
                </span>
              </template>
            </template>

            <template
              v-else-if="column.field === 'type'"
            >
              <!-- <template v-if="isEditing && props.row.id === selectedCustomFieldId">
              <b-select
                v-model="fieldType"
                size="is-small"
                placeholder="Select field type"
              >
                <option
                  v-for="option in fieldTypes"
                  :key="option.id"
                  :value="option.code"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </template> -->
              <template>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row)"
                >
                  {{ props.row[column.field] }}
                </span>
              </template>
            </template>

            <template
              v-else-if="
                column.field === 'createdAt' || column.field === 'updatedAt'
              "
            >
              {{ props.row[column.field] | date }}
            </template>

            <template v-else>
              {{ props.row[column.field] }}
            </template>
          </b-tooltip>
        </template>
      </b-table-column>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                type="is-info"
                size="is-small"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(pageCount, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="pageCount"
                >
                  {{ pageCount }}
                </b-dropdown-item>
                <hr
                  :key="pageCount"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="lookups" />
      </template>
    </b-table>

    <b-modal
      ref="inlineEditingSavePrompt"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateCustomField"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { TableFooter, EmptyTable, InlineEditingSavePrompt } from '@/components/Shared';
import SearchMixin from '@/mixins/SearchMixin';

export default {
  name: 'CustomFieldsTable',

  components: {
    TableFooter,
    EmptyTable,
    InlineEditingSavePrompt,
  },

  filters: {
    /**
     * Truncate text that is more than 100 characters in length
     *
     * @param {String} text text to truncate
     * @returns truncated text
     */
    truncateText(text) {
      const charactersLength = 100;
      const textEnding = '...';
      if (text.length <= charactersLength) {
        return text;
      }
      return text.slice(0, charactersLength - textEnding.length) + textEnding;
    },
  },

  mixins: [SearchMixin],

  data: () => ({
    checkedRows: [],

    fieldTypes: [
      {
        id: 201,
        name: 'text input',
        code: 'TEXT_INPUT',
      },
      {
        id: 202,
        name: 'dropdown',
        code: 'DROP_DOWN',
      },
      {
        id: 203,
        name: 'multi-select',
        code: 'MULTI_SELECT',
      },
    ],
    objectIds: [
      {
        id: 301,
        name: 'accounts',
        code: 'Account',
      },
      {
        id: 302,
        name: 'contacts',
        code: 'Contact',
      },
      {
        id: 303,
        name: 'leads/opportunities',
        code: 'Lead_Opportunity',
      },
    ],

    total: 0,
    page: 1,
    perPage: 25,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    sortField: 'updatedAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    isEditing: false,
    selectedCustomFieldId: '',

    isSavePromptActive: false,

    savePromptOpen: false,

    filters: {},

    exportFields: {},

    searchClause: '',

    columns: [
      {
        id: '1',
        label: 'Field Name',
        field: 'label',
        sortable: true,
        searchable: true,
        searchField: 'label',
        exportField: 'label',
        sticky: false,
        visible: true,
      },
      {
        id: '2',
        label: 'Object',
        field: 'objectName',
        sortable: true,
        searchable: true,
        searchField: 'objectName',
        exportField: 'objectName',
        sticky: false,
        visible: true,
      },
      {
        id: '3',
        label: 'Is Required',
        field: 'isRequired',
        sortable: true,
        searchable: true,
        searchField: 'isRequired',
        exportField: 'isRequired',
        sticky: false,
        visible: true,
      },
      {
        id: '4',
        label: 'Type',
        field: 'type',
        sortable: true,
        searchable: true,
        searchField: 'type',
        exportField: 'type',
        sticky: false,
        visible: true,
      },
      {
        id: '5',
        label: 'Lookup Name',
        field: 'lookupName',
        sortable: true,
        searchable: true,
        searchField: 'lookupName',
        exportField: 'lookupName',
        sticky: false,
        visible: true,
      },
      {
        id: '6',
        label: 'Actions',
        field: 'actions',
        sortable: true,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: true,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },

  }),

  computed: {
    ...mapGetters({
      grid: 'Grids/getCustomFieldsGrid',
      loading: 'DataGrids/getLoading',
      customFieldsData: 'DataGrids/getCustomFields',
      lookupCodes: 'Settings/getLookupsList',
      customField: 'Settings/getCustomField',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

    label: {
      get() {
        return this.$store.state.Settings.customFields.customField.label;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FIELD_NAME', value);
      },
    },

    objectName: {
      get() {
        return this.$store.state.Settings.customFields.customField.objectName;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FIELD_OBJECT_NAME', value);
      },
    },

    isRequired: {
      get() {
        return this.$store.state.Settings.customFields.customField.isRequired;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FIELD_REQUIRED', value);
      },
    },

    fieldType: {
      get() {
        return this.$store.state.Settings.customFields.customField.type;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FIELD_TYPE', value);
      },
    },

    lookupName: {
      get() {
        return this.$store.state.Settings.customFields.customField.lookupName;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FIELD_LOOKUPS', value);
      },
    },
  },

  watch: {
    perPage: {
      handler() {
        this.fetchCustomFields();
      },
      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchCustomFieldsGrid', {
        gridName: 'customFields',
        organizationId,
      });
      await this.$store.dispatch('Settings/fetchLookups');

      if (response) {
        const isConfigurationValid = this.configurationValidator();
        if (isConfigurationValid) {
          this.setGrid();
        }
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'customFields',
          customizations: this.customizations,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    const payload = { ...this.$route.query };
    delete payload?.activeTab;
    this.filters = payload;
    this.fetchCustomFields();
    this.$store.dispatch('Lookups/fetchActivityTypes');
    this.$store.dispatch('Lookups/fetchTaskPriorities');
    this.$store.dispatch('Lookups/fetchTaskTypes');
  },

  destroyed() {
    if (localStorage.getItem('access_token')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'customFields',
        customizations: this.customizations,
      });
    }
    this.$store.commit('Activities/SET_ACTIVITIES', []);
  },

  methods: {
    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    configurationValidator() {
      const defaultConfiguration = this.columns.map((el) => el.label).sort();
      const userConfiguration = this.grid.columns.map((el) => el.label).sort();
      const result = defaultConfiguration.every(
        (configuration, index) => configuration === userConfiguration[index],
      );
      return result;
    },

    onClickOutsideEditing(row) {
      if (row.id && this.isEditing) {
        if (row.id !== this.selectedCustomFieldId) {
          this.savePromptOpen = true;
          this.isSavePromptActive = true;
        }
      }
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.savePromptOpen = true;
        this.isSavePromptActive = true;
      }
    },

    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex((el) => el.label === a.label)
          - this.grid.columns.findIndex((el) => el.label === b.label),
      );
      const newColumns = this.columns.map((el, index) => ({
        ...el,
        visible: this.grid.columns[index].visible,
      }));
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
    },

    /**
     * fetchCustomFields
     *
     */
    async fetchCustomFields() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt).toISOString().substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt).toISOString().substring(0, 10);
        }
      }

      try {
        await this.$store.dispatch('DataGrids/fetchCustomFields', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
        });
        this.total = this.customFieldsData.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onPageChange
     *
     * handle page change events
     * @param {Number} page
     */
    onPageChange(page) {
      this.page = page;
      this.from += this.perPage;
      this.fetchCustomFields();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchCustomFields();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = { ...this.filters, ...filters };
      this.fetchCustomFields();
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.customFieldsTable.resetMultiSorting();
      this.$refs.customFieldsTable.filters = {};
      this.filters = {};
      this.fetchCustomFields();
    },

    /**
     * onEdit
     *
     * @param {object} customField
     */
    onEdit(customField) {
      this.$store.dispatch('Settings/fetchCustomField', customField.id);
      this.selectedCustomFieldId = customField.id;
      this.$emit('is-editing', true);
      this.isEditing = true;
    },

    /**
     * searchCustomField
     *
     */
    searchCustomField(value) {
      this.searchClause = value;
      this.searchClause = value;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchCustomFields();
      }, 800);
    },

    /**
     * updateCustomField
     *
     */
    async updateCustomField() {
      const payload = { ...this.customField };
      try {
        await this.$store.dispatch('Settings/updateCustomField', {
          fieldId: this.selectedCustomFieldId,
          customField: payload,
        });
        await this.fetchCustomFields();
        this.isEditing = false;
        this.$emit('is-editing', false);
        this.selectedCustomFieldId = null;
        this.$store.commit('Settings/CLEAR_CUSTOM_FIELD');
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * cancelUpdate
     *
     */
    async cancelUpdate() {
      try {
        await this.fetchCustomFields();
        this.isEditing = false;
        this.$emit('is-editing', false);
        this.selectedCustomFieldId = null;
        this.$store.commit('Settings/CLEAR_CUSTOM_FIELD');
      } catch (error) {
        console.error(error);
      }
    },

    async deleteCustomField(payload) {
      try {
        await this.$store.dispatch('Settings/deleteCustomField', {
          id: payload.id,
        });
        await this.fetchCustomFields();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
