<template>
  <div>
    <div class="level">
      <div class="level-left" />

      <div class="level-right">
        <div class="level-item">
          <b-button
            v-if="isEditing"
            ref="saveEntryButton"
            size="is-small"
            icon-left="save"
            type="is-success"
            @click="updateLookup"
          >
            save
          </b-button>
        </div>
      </div>
    </div>

    <b-table
      ref="lookupsTable"
      v-click-outside-dg="onClickOutsideTable"
      :mobile-cards="false"
      :loading="loading"
      :data="lookUpsList.content"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
      @update:selected="onClickOutsideEditing"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <template>
            <b-input
              v-show="
                column.searchField === 'createdAt' ||
                  column.searchField === 'updatedAt'
              "
              v-model="props.filters[column.searchField]"
              type="date"
              icon="calendar"
              size="is-small"
            />

            <b-input
              v-show="
                column.searchField === 'name' ||
                  column.searchField === 'value'
              "
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
            />

            <b-switch
              v-show="column.searchField === 'isActive'"
              v-model="props.filters[`${column.searchField}`]"
              size="is-small"
            />
          </template>
        </template>

        <template v-slot="props">
          <template v-if="column.field === 'actions'">
            <div class="buttons">
              <b-button
                type="is-info"
                size="is-small"
                @click="updateLookupStatus(props.row)"
              >
                {{ props.row.isActive ? 'Deactivate' : 'Activate' }}
              </b-button>
            </div>
          </template>

          <template
            v-else-if="column.searchField === 'name'"
          >
            {{ props.row[column.field] }}
          </template>

          <template v-else-if="column.field === 'value'">
            <b-taglist>
              <b-tag
                v-for="(value, lookupIndex) in props.row.value.split(',')"
                :key="lookupIndex"
                type="is-info"
              >
                {{ value }}
              </b-tag>
            </b-taglist>
          </template>

          <template v-else-if="column.field === 'isActive'">
            {{ props.row[column.field] }}
          </template>

          <template
            v-else-if="
              column.field === 'createdAt' || column.field === 'updatedAt'
            "
          >
            {{ props.row[column.field] | date }}
          </template>
        </template>
      </b-table-column>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                type="is-info"
                size="is-small"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(pageCount, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="pageCount"
                >
                  {{ pageCount }}
                </b-dropdown-item>
                <hr
                  :key="pageCount"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="lookups" />
      </template>
    </b-table>

    <b-modal
      ref="inlineEditingSavePrompt"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateLookup"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { TableFooter, EmptyTable, InlineEditingSavePrompt } from '@/components/Shared';
import SearchMixin from '@/mixins/SearchMixin';

export default {
  name: 'LookupsTable',

  components: {
    TableFooter,
    EmptyTable,
    InlineEditingSavePrompt,
  },

  filters: {
    /**
     * Truncate text that is more than 100 characters in length
     *
     * @param {String} text text to truncate
     * @returns truncated text
     */
    truncateText(text) {
      const charactersLength = 100;
      const textEnding = '...';
      if (text.length <= charactersLength) {
        return text;
      }
      return text.slice(0, charactersLength - textEnding.length) + textEnding;
    },
  },

  mixins: [SearchMixin],

  data: () => ({
    checkedRows: [],

    total: 0,
    page: 1,
    perPage: 25,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    sortField: 'updatedAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    isEditing: false,
    selectedLookupId: '',

    isSavePromptActive: false,

    savePromptOpen: false,

    filters: {},

    exportFields: {},

    searchClause: '',

    columns: [
      {
        id: '1',
        label: 'Lookup Name',
        field: 'name',
        sortable: true,
        searchable: true,
        searchField: 'name',
        exportField: 'name',
        sticky: false,
        visible: true,
      },
      {
        id: '2',
        label: 'Lookup Value',
        field: 'value',
        sortable: true,
        searchable: true,
        searchField: 'value',
        exportField: 'value',
        sticky: false,
        visible: true,
      },
      {
        id: '3',
        label: 'Active',
        field: 'isActive',
        sortable: true,
        searchable: true,
        searchField: 'isActive',
        exportField: 'isActive',
        sticky: false,
        visible: true,
      },
      {
        id: '4',
        label: 'Actions',
        field: 'actions',
        sortable: true,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: true,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },

  }),

  computed: {
    ...mapGetters({
      grid: 'Grids/getLookupsGrid',
      loading: 'DataGrids/getLoading',
      lookUpsList: 'DataGrids/getLookups',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

  },

  watch: {
    perPage: {
      handler() {
        this.fetchLookups();
      },
      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchLookupsGrid', {
        gridName: 'lookups',
        organizationId,
      });
      if (response) {
        const isConfigurationValid = this.configurationValidator();
        if (isConfigurationValid) {
          this.setGrid();
        }
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'lookups',
          customizations: this.customizations,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    this.filters = { ...this.$route.query };
    this.fetchLookups();
  },

  destroyed() {
    if (localStorage.getItem('access_token')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'lookups',
        customizations: this.customizations,
      });
    }
    this.$store.commit('Settings/SET_LOOKUPS', []);
  },

  methods: {
    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    configurationValidator() {
      const defaultConfiguration = this.columns.map((el) => el.label).sort();
      const userConfiguration = this.grid.columns.map((el) => el.label).sort();
      const result = defaultConfiguration.every(
        (configuration, index) => configuration === userConfiguration[index],
      );
      return result;
    },

    onClickOutsideEditing(row) {
      if (row.id && this.isEditing) {
        if (row.id !== this.selectedLookupId) {
          this.savePromptOpen = true;
          this.isSavePromptActive = true;
        }
      }
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.savePromptOpen = true;
        this.isSavePromptActive = true;
      }
    },

    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex((el) => el.label === a.label)
          - this.grid.columns.findIndex((el) => el.label === b.label),
      );
      const newColumns = this.columns.map((el, index) => ({
        ...el,
        visible: this.grid.columns[index].visible,
      }));
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
    },

    /**
     * fetchLookups
     *
     */
    async fetchLookups() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt).toISOString().substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt).toISOString().substring(0, 10);
        }
      }

      try {
        await this.$store.dispatch('DataGrids/fetchLookups', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
        });
        this.total = this.lookUpsList.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    async updateLookupStatus(payload) {
      await this.$buefy.dialog.confirm({
        message: 'Are you sure?',
        closeOnConfirm: true,
        type: 'is-info',
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Settings/updateLookupStatus', {
              id: payload.id,
              data: {
                isActive: !payload.isActive,
              },
            });
            await this.fetchLookups();
          } catch (error) {
            console.error(error);
          }
        },
      });
    },

    /**
     * onPageChange
     *
     * handle page change events
     * @param {Number} page
     */
    onPageChange(page) {
      this.page = page;
      this.from += this.perPage;
      this.fetchLookups();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchLookups();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = { ...this.filters, ...filters };
      this.fetchLookups();
    },

    // /**
    //  * resetFilters
    //  *
    //  */
    // resetFilters() {
    //   this.$refs.activitiesTable.resetMultiSorting();
    //   this.$refs.activitiesTable.filters = {};
    //   this.filters = {};
    //   this.fetchLookups();
    // },

    /**
     * searchLookup
     *
     */
    searchLookup() {
      // this.searchClause = value;
      // this.searchClause = value;
      // if (this.timer) {
      //   clearTimeout(this.timer);
      //   this.timer = null;
      // }
      // this.timer = setTimeout(() => {
      //   this.fetchActivities();
      // }, 800);
    },

    /**
     * updateLookup
     *
     */
    async updateLookup() {
      // }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
